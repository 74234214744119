<template>

  <a-row>
    <a-card class="general_card" :bordered="false" title="Dosya Listesi" :loading="loading">
      <template #extra>
        <UnorderedListOutlined/>

        <a-button class="ant-btn-primary" @click="showMyModal()">
          <CloudUploadOutlined />
          Yükle
        </a-button>
        <!--        <router-link class="ant-btn-primary" tag="a" to="/new_company">-->
        <!--          <PlusOutlined/>-->
        <!--          Ekle-->
        <!--        </router-link>-->

      </template>


      <a-col :span="24">
        <UploadFile v-if="!loading" ref="UploadFile" @uploaded="getFiles()"/>

        <a-table :data-source="files" :columns="columns" @change="handleChange" :row-key="record => record.id">

          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                  ref="searchInput"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined/>
                </template>
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
          </template>

          <template #actions="{ record }">

            <a-space v-if="record.extension !== 'folder'">
              <a :href="record.link"  type="primary"  target="_blank" class="ant-btn ant-btn-primary">
                <ChromeOutlined/>
                Git
              </a>

              <a-popconfirm  title="Google Cloud'dan silmek istediğinize emin misiniz ? Önce ilişkili kursları silin lütfen " @confirm="deleteAndRefresh(record.name)">
                <a-button danger>Sil</a-button>
                <!--            <a href="#">Sil</a>-->
              </a-popconfirm>
            </a-space>


          </template>
        </a-table>
      </a-col>

    </a-card>
  </a-row>
</template>

<script>
import {UnorderedListOutlined, SearchOutlined,CloudUploadOutlined,ChromeOutlined} from '@ant-design/icons-vue'
import {onMounted, reactive, computed, toRefs, ref, inject} from 'vue'
import {formatBytes} from '../../libs/helpers'
import UploadFile from './components/UploadFile.vue'

export default {
  name: 'FileList',
  components: {
    ChromeOutlined,
    SearchOutlined,
    UploadFile,
    CloudUploadOutlined,
    UnorderedListOutlined
  },

  setup() {

    const localState = reactive({files: [], filter: {}})
    const axios = inject('axios')
    const loading = ref(true)

    const UploadFile = ref(null)

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    })

    const searchInput = ref()

    const getFiles = () => {

      axios.post('/google/files',{path:'panel_files'})
          .then(response => {

            localState.files = response.data.files

            loading.value = false

          })
    }

    onMounted(() => {

      getFiles()

    })
    const columns = computed(() => {

      return [
        {
          key: 'name',
          title: 'İsim',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'fileSize',
          title: 'Dosya Boyutu',
          dataIndex: 'size',
          // customRender: ({text, record, index}) => {return `${record.course.category.name}: ${record.course.name}`}
          customRender: ({record}) => {
            if(record.size ==="0") return 0
            return formatBytes(record.size)
          }
        },

        {
          key: 'content-type',
          title: 'Uzantı',
          dataIndex:'extension',
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          slots: {
            customRender: 'actions'
          }
        }

      ]
    })
    const handleChange = (pagination, filters, sorter) => {
      localState.filter = filters
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = clearFilters => {
      clearFilters()
      state.searchText = ''
    }

    const showMyModal = () => {
      UploadFile.value.showModal()
    }

    const hideOrShowButtons = ref(new Array(localState.files.length).fill(false))
    const deleteAndRefresh = (name) => {
      axios.delete(`google/files`,{data:{file_name:name}})
          .then(() => {
            getFiles()
          })

    }


    return {
      UploadFile,
      showMyModal,
      getFiles,
      ...toRefs(localState),
      hideOrShowButtons,
      columns,
      handleChange,
      loading,
      handleSearch,
      handleReset,
      searchText: '',
      searchInput,
      searchedColumn: '',
      deleteAndRefresh
    }
  }

}
</script>
<style lang="scss" scoped>

.ant-popover-inner-content p {
  max-width: 295px;
  overflow-x: auto;
  max-height: 50px;
  white-space: nowrap;
  border-bottom: 1px solid #d8d8d8;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #d8d8d8;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #1890FF;
    border-radius: 2px;
  }
}
</style>
