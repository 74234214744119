<template>
  <a-modal :title="'Upload'" v-model:visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" width="800px">
    <a-upload list-type="picture" v-model:file-list="fileList" :customRequest="handleCustomRequest">
      <a-button>
        <upload-outlined></upload-outlined>
        Upload
      </a-button>
      <div v-if="isUploading" style="margin-top: 10px">
        <a-spin />
        <span style="margin-left: 10px">%{{ uploadingPercent }}{{ uploadingPercent === 100 ? " Hazır halegetiriliyor":""}}</span>
      </div>
    </a-upload>

    <template #footer>
      <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOk">OK</a-button>
    </template>

  </a-modal>
</template>

<script>
import { inject, ref } from 'vue'
import config from '../../../../config'

export default {
  name: 'UploadFile',
  emits: ['uploaded'],
  setup(_, { emit }) {

    const axios = inject('axios')

    const isEditing = ref(false)

    const visible = ref(false)
    const confirmLoading = ref(false)


    const uploadingPercent = ref(0)
    const isUploading = ref(false)


    const handleCustomRequest = async (options) => {

      const { file, onSuccess, onError, onProgress } = options

      isUploading.value = true
      uploadingPercent.value = 0
      let url

      await axios.post(`${config.BACKEND_URL}/google/get-upload-url`, { filename: file.name }).then(response => url = response.data.url)

      try {
        await axios.put(url,
          file,
          {
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              uploadingPercent.value = Math.round((loaded * 100) / total)
              console.log(uploadingPercent.value)
              // onProgress(progressEvent,file)
              onProgress(uploadingPercent.value)
            },
            headers: {
              'Content-Type': file.type
            }
          },

        )
        onSuccess('Yükleme başarı ile gerçekleşti')
        setTimeout(() => {
          emit('uploaded')
        }, 2000)

      } catch (error) {
        onError('Bir hata meydana geldi')
        console.log(error)
      } finally {
        isUploading.value = false
      }


    }

    const showModal = async () => {
      visible.value = true
    }

    const handleOk = () => {
      visible.value = false
    }

    return {
      handleCustomRequest,
      fileList: ref([]),
      uploadingPercent,
      isUploading,
      isEditing,
      visible,
      confirmLoading,
      showModal,
      handleOk
    }

  }


}
</script>
